import React, { useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import { Tooltip } from '@material-ui/core';
import 'react-chatbot-kit/build/main.css';
import config from '../modules/chatbot/chatbotConfig';
import ActionProvider from '../modules/chatbot/ActionProvider';
import MessageParser from '../modules/chatbot/ParserMessage';
import ChatIcon from '../assets/img/message.png';

import '../css/chatbot.css';

const AiChatbot = () => {
  const [showBot, setShowBot] = useState(false);

  return (
    <div>
      {showBot && (
        <div className='chatbot-container'>
          <div className='chatbot-header'>OurExpertDoc AI Chatbot</div>
          <div className='emergency-banner'>For emergencies, contact your emergency response center</div>

          <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
          <div className='chatbot-disclaimer'>OurExpertDoc is not responsible for AI-generated responses.</div>
        </div>
      )}

      {!showBot && (
        <Tooltip
          title={<span className='tooltip-text'>Hello, what can I help you with today?</span>}
          placement='left'
          arrow>
          <button className='chatbot-icon' onClick={() => setShowBot((prev) => !prev)}>
            <div>{showBot ? 'X' : <img src={ChatIcon} width='30' height='30' />}</div>
          </button>
        </Tooltip>
      )}

      {showBot && (
        <button className='chatbot-icon' onClick={() => setShowBot((prev) => !prev)}>
          <div>{showBot ? 'X' : <img src={ChatIcon} width='30' height='30' />}</div>
        </button>
      )}
    </div>
  );
};

export default AiChatbot;
