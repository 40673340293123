import React from 'react';
import axios from 'axios';

const URL = 'user/v1/chatbot/';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const formatMessageWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        const cleanURL = part.replace(/[.,!?]+$/, '');

        return (
          <a key={index} href={cleanURL} target='_blank' rel='noopener noreferrer' className='chatbot-link'>
            {cleanURL}
          </a>
        );
      }
      return part;
    });
  };

  const handleMessage = async (message) => {
    try {
      const fullUrl = `${process.env.REACT_APP_BASE_URL_AWS}${URL}`;
      const res = await axios.post(fullUrl, {
        question: message,
      });

      let botResponse = res.data.answer;
      const formattedResponse = formatMessageWithLinks(botResponse);
      const botMessage = createChatBotMessage(formattedResponse);

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    } catch (error) {
      console.error('Chatbot API Error:', error);
      const errorMessage = createChatBotMessage("Sorry, I couldn't process your request. Please try again later.");

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, errorMessage],
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          actions: { handleMessage },
        })
      )}
    </div>
  );
};

export default ActionProvider;
