import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'OurExpertDoc AI Chatbot';

const config = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(
      `Hi! I am ${botName}. I can help answer your questions related to OurExpertDoc. Ask me anything!`
    ),
  ],
};

export default config;
