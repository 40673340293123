import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    actions.handleMessage(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          parse: parse,
          actions,
        })
      )}
    </div>
  );
};

export default MessageParser;
