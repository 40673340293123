import { createStore, applyMiddleware } from 'redux';
import allReducers from '../data/reducers';
import thunk from 'redux-thunk';
import * as storage from './localStorageUtility';

import { signOut, updateAccessToken, updateToken, updateUser } from '../data';

const store = createStore(allReducers, applyMiddleware(thunk));

const { dispatch } = store;

const moment = require('moment');

export const getFullName = (user) => {
  return user.first_name + (user.middle_name ? ' ' + user.middle_name : '') + ' ' + user.last_name;
};

export const calculateBMI = (heightInput, wieghtInput, heightUnit, weightUnit) => {
  const heightConstant = 3.281;
  const weightConstant = 2.205;

  if (heightUnit === 'feet') {
    heightInput /= heightConstant;
  }

  if (weightUnit === 'pounds') {
    wieghtInput /= weightConstant;
  }

  return Math.floor(wieghtInput / (heightInput * heightInput));
};

export const mapToNull = (value) => {
  if (value === null) {
    return '';
  } else {
    return value;
  }
};
export const mapToUnderscore = (start, end) => {
  if (start === null || end === null) {
    return '';
  } else {
    return '-';
  }
};

export const getObject = (array, id) => {
  const objectToFind = array.filter((obj) => obj.id === id);
  return objectToFind[0];
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const sortObjectArrayByProperty = (myArr, prop) => {
  return myArr.sort(function (a, b) {
    return a[prop] - b[prop];
  });
};

export const dateFormatter = (date, format) => moment(new Date(date)).format(format);

export const rand = () => Math.round(Math.random() * 20) - 10;

export const appendText = (text, editable) => {
  if (text) {
    let textContent;
    if (text.search(',') !== '-1') {
      const textSubstr = text.split(',');
      textContent = textSubstr[1];
    }
    if (
      textContent === 'You have been posted with a question from one of your patients.' ||
      textContent === 'has started a second opinion with you.'
    ) {
      return `?editable=${editable}#message`;
    } else {
      return `?editable=${editable}`;
    }
  }
};

export const getEmbedUrl = (videoId) => {
  return videoId.startsWith('https://') ? videoId : `https://www.youtube.com/embed/${videoId}`;
};

export const redirectUrl = (page, type, id) => {
  if (type && id) {
    if (type === 'physician') {
      return `/${page}/physician/${id}`;
    } else {
      return `/${page}/selectphysician/${id}`;
    }
  } else {
    return `/${page}`;
  }
};

export const getYoutubeThumbnailUrl = (videoId) => {
  return videoId.startsWith('https://')
    ? 'https://cdn.abcotvs.com/dip/images/15592022_CancerTreatmentPatient.jpg'
    : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};

export const sortByName = (arr) => {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
};

export const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

export const convertToLowercase = (str) => {
  return str.toLowerCase().replace(/\s+/g, '-');
};

export const getBlogPreviewContent = (content) => {
  if (content.length > 220) {
    const slicedContent = content.slice(0, 220);
    return `${slicedContent.slice(0, slicedContent.lastIndexOf(' '))}....`;
  }
  return content;
};

export const signUserOut = () => {
  dispatch(signOut());
  sessionStorage.clear();
  storage.setIsLogged('false');
  dispatch(updateToken(''));
  storage.setToken('');
  dispatch(updateAccessToken(''));
  storage.setAccessToken('');
  dispatch(updateUser(null));
  storage.setUser(null);
};

export default calculateBMI;
